* {
    font-family: Arial;
  }
.modal_card {
   border-style:solid;
   border-width:1px;
   border-color:white;
   border-radius: 5px;
   padding: 20px;
   box-shadow: 2px 3px 3px 2px rgba(0,0,0,0.2), 2px 1px 1px 2px rgba(0,0,0,0.19);
   box-sizing:content-box;
   width: 100%;
}
.modal_card1 {
   border-style:solid;
   border-width:1px;
   border-color:white;
   border-radius: 5px;
   padding: 5px;
   box-shadow: 2px 3px 3px 2px rgba(0,0,0,0.2), 2px 1px 1px 2px rgba(0,0,0,0.19);
   box-sizing:content-box;
   vertical-align: middle;
}

.fs25 {
   font-size: 25pt;
}

.met {
   font-size:x-large;
   font-weight: bold;
   color: #40843e;
}

.notmet {
   font-size:x-large;
   font-weight: bold;
   color: #b72418 ;
}

.m_step {
   color: #ffffff; 
   background-color: #156db6; 
   font-weight: bold; 
   border-style:solid;
   border-width: 1px;
   border-color:white;
   border-radius: 5px;
   padding: 20px;
   box-shadow: 1px 2px 2px 1px rgba(0,0,0,0.2), 2px 1px 1px 2px rgba(0,0,0,0.19);
   box-sizing:content-box;
   vertical-align: middle;
}

.m_step1 {
   color: #000000 ;  
   background-color: #56b7e6 ; 
   border-style:solid;
   border-width:1px;
   border-color:white;
   border-radius: 5px;
   padding: 5px;
   box-shadow: 1px 2px 2px 1px rgba(0,0,0,0.2), 2px 1px 1px 2px rgba(0,0,0,0.19);
   box-sizing:content-box;
   width: 16%;
}

.th {
   color: #000000 ;  
   background-color: #56b7e6 ; 
   font-weight: bold;
   text-align: center;
   border-style:solid;
   border-width:1px;
   border-color:white;
   border-radius: 5px;
   padding: 5px;
   box-shadow: 1px 2px 2px 1px rgba(0,0,0,0.2), 2px 1px 1px 2px rgba(0,0,0,0.19);
   box-sizing:content-box;
}

.modal_header {
   float: left;
   text-align: left;
   font-size: 12pt;
   font-weight: bold;
   width: 100%;
   padding-bottom: 10px;
}

.modal_div1 {
   float: left;
}

.modal_div2 {
   width: 100%;
}

.modal_li {
   padding-bottom: 10px;

}
.A_color
{
  color: #278143;
  font-weight: bold;
}
.B_color
{
  color: #39398C;
  font-weight: bold;
}
.batch
{
  background-color: #FFFFFF;
  border: 1px solid #7F9DB9;
  border-spacing: 0px;
  color: #363534;
  font-family: 'Courier New', Courier, fixed;
  font-size: 12pt;
  font-style: normal;
  font-weight: bold;
  margin: 0px;
  padding: 3px;
  vertical-align: middle;
}
.bold_text
{
  font-family: Arial, Helvetica;
  font-size: 12pt;
  font-weight: bold;
}
.C_color
{
  color: #9C118B;
  font-weight: bold;
}
.ctgbuttons
{
  color: #012169;
  font-family: Arial, Helvetica;
  font-size: 14pt;
  font-weight: bold;
  padding: 0px;
}
.D_color
{
  color: #E0803D;
  font-weight: bold;
}
.data
{
  background-color: #FFFFFF;
  border-bottom-color: #577B9C;
  border-bottom-width: 1px;
  border-left-color: #CEDAE4;
  border-left-width: 0px;
  border-right-color: #577B9C;
  border-right-width: 1px;
  border-spacing: 0px;
  border-style: solid;
  border-top-color: #CEDAE4;
  border-top-width: 0px;
  color: #363534;
  font-family: Arial, Helvetica;
  font-size: 12pt;
  font-style: normal;
  font-weight: normal;
  margin: 0px;
  padding: 3px;
  vertical-align: top;
}
.dataemphasis
{
  background-color: #E7E3DB;
  border-bottom-color: #577B9C;
  border-bottom-width: 1px;
  border-left-color: #CEDAE4;
  border-left-width: 0px;
  border-right-color: #577B9C;
  border-right-width: 1px;
  border-spacing: 0px;
  border-style: solid;
  border-top-color: #CEDAE4;
  border-top-width: 0px;
  color: #363534;
  font-family: Arial, Helvetica;
  font-size: 12pt;
  font-style: normal;
  font-weight: bold;
  margin: 0px;
  padding: 3px;
  vertical-align: top;
}
.dataemphasis_tlr
{
  background-color: #DA3E26;
  border-bottom-color: #577B9C;
  border-bottom-width: 1px;
  border-left-color: #CEDAE4;
  border-left-width: 0px;
  border-right-color: #577B9C;
  border-right-width: 1px;
  border-spacing: 0px;
  border-style: solid;
  border-top-color: #CEDAE4;
  border-top-width: 0px;
  color: #FFFFFF;
  font-family: Arial, Helvetica;
  font-size: 12pt;
  font-style: normal;
  font-weight: bold;
  margin: 0px;
  padding: 3px;
  vertical-align: top;
}
.dataemphasis_xsmall
{
  background-color: #FFFFFF;
  border-bottom-color: #577B9C;
  border-bottom-width: 1px;
  border-left-color: #CEDAE4;
  border-left-width: 0px;
  border-right-color: #577B9C;
  border-right-width: 1px;
  border-spacing: 0px;
  border-style: solid;
  border-top-color: #CEDAE4;
  border-top-width: 0px;
  color: #363534;
  font-family: Arial, Helvetica;
  font-size: 7pt;
  font-style: normal;
  font-weight: normal;
  margin: 0px;
  padding: 3px;
  vertical-align: top;
}
.data_tlg
{
  background-color: #418708;
  border-bottom-color: #577B9C;
  border-bottom-width: 1px;
  border-left-color: #CEDAE4;
  border-left-width: 0px;
  border-right-color: #577B9C;
  border-right-width: 1px;
  border-spacing: 0px;
  border-style: solid;
  border-top-color: #CEDAE4;
  border-top-width: 0px;
  color: #363534;
  font-family: Arial, Helvetica;
  font-size: 12pt;
  font-style: normal;
  font-weight: normal;
  margin: 0px;
  padding: 3px;
  vertical-align: top;
}
.data_tlr
{
  background-color: #DA3E26;
  border-bottom-color: #577B9C;
  border-bottom-width: 1px;
  border-left-color: #CEDAE4;
  border-left-width: 0px;
  border-right-color: #577B9C;
  border-right-width: 1px;
  border-spacing: 0px;
  border-style: solid;
  border-top-color: #CEDAE4;
  border-top-width: 0px;
  color: #FFFFFF;
  font-family: Arial, Helvetica;
  font-size: 12pt;
  font-style: normal;
  font-weight: normal;
  margin: 0px;
  padding: 3px;
  vertical-align: top;
}
.data_xsmall
{
  background-color: #FFFFFF;
  border-bottom-color: #577B9C;
  border-bottom-width: 1px;
  border-left-color: #CEDAE4;
  border-left-width: 0px;
  border-right-color: #577B9C;
  border-right-width: 1px;
  border-spacing: 0px;
  border-style: solid;
  border-top-color: #CEDAE4;
  border-top-width: 0px;
  color: #363534;
  font-family: Arial, Helvetica;
  font-size: 7pt;
  font-style: normal;
  font-weight: normal;
  margin: 0px;
  padding: 3px;
  vertical-align: top;
}
.F_color
{
  color: #CA313C;
  font-weight: bold;
}
.header
{
  background-color: #E7E3DB;
  border-bottom-color: #577B9C;
  border-bottom-width: 1px;
  border-left-color: #CEDAE4;
  border-left-width: 0px;
  border-right-color: #577B9C;
  border-right-width: 1px;
  border-spacing: 0px;
  border-style: solid;
  border-top-color: #CEDAE4;
  border-top-width: 0px;
  color: #363534;
  font-family: Arial, Helvetica;
  font-size: 12pt;
  font-style: normal;
  font-weight: bold;
  margin: 0px;
  padding: 3px;
  text-align: center;
  vertical-align: bottom;
}
.header_xsmall
{
  background-color: #E7E3DB;
  border-bottom-color: #577B9C;
  border-bottom-width: 1px;
  border-left-color: #CEDAE4;
  border-left-width: 0px;
  border-right-color: #577B9C;
  border-right-width: 1px;
  border-spacing: 0px;
  border-style: solid;
  border-top-color: #CEDAE4;
  border-top-width: 0px;
  color: #363534;
  font-family: Arial, Helvetica;
  font-size: 7pt;
  font-style: normal;
  font-weight: bold;
  margin: 0px;
  padding: 3px;
  text-align: center;
  vertical-align: bottom;
}
.headere
{
  background-color: #E7E3DB;
  border-bottom-color: #577B9C;
  border-bottom-width: 1px;
  border-left-color: #CEDAE4;
  border-left-width: 0px;
  border-right-color: #577B9C;
  border-right-width: 1px;
  border-spacing: 0px;
  border-style: solid;
  border-top-color: #CEDAE4;
  border-top-width: 0px;
  color: #363534;
  font-family: Arial, Helvetica;
  font-size: 12pt;
  font-style: normal;
  font-weight: bold;
  margin: 0px;
  padding: 3px;
  text-align: left;
  vertical-align: bottom;
}
.linecontent
{
  background-color: #E7E3DB;
  border: 1px solid #CECFD2;
  border-spacing: 0px;
  color: #000000;
  font-family: Arial, Helvetica;
  font-size: 12pt;
  font-style: normal;
  font-weight: bold;
  margin: 0px;
  padding: 3px;
  text-align: left;
  vertical-align: middle;
}
.metauser
{
  background-color: #E7E3DB;
  border-bottom-color: #577B9C;
  border-bottom-width: 1px;
  border-left-color: #CEDAE4;
  border-left-width: 0px;
  border-right-color: #577B9C;
  border-right-width: 1px;
  border-spacing: 0px;
  border-style: solid;
  border-top-color: #CEDAE4;
  border-top-width: 0px;
  color: #363534;
  font-family: Arial, Helvetica;
  font-size: 12pt;
  font-style: normal;
  font-weight: bold;
  margin: 0px;
  padding: 3px;
  text-align: center;
  vertical-align: middle;
}
.nav
{
  background-color: #FFFFFF;
  border: 0px none #000000;
  padding: 0px;
  text-align: center;
  vertical-align: bottom;
  cursor: pointer}
.normal_text
{
  font-family: Arial, Helvetica;
  font-size: 12pt;
}
.notecontent
{
  background-color: #FFFFFF;
  border-bottom-color: #577B9C;
  border-bottom-width: 1px;
  border-left-color: #CEDAE4;
  border-left-width: 0px;
  border-right-color: #577B9C;
  border-right-width: 1px;
  border-spacing: 0px;
  border-style: solid;
  border-top-color: #CEDAE4;
  border-top-width: 0px;
  color: #363534;
  font-family: Arial, Helvetica;
  font-size: 12pt;
  font-style: normal;
  font-weight: normal;
  margin: 0px;
  padding: 3px;
  vertical-align: top;
}
.paragraph
{
  background-color: transparent;
  border: 0px hidden #FFFFFF;
  border-spacing: 0px;
  color: #000000;
  font-family: Arial, Helvetica;
  font-size: 10pt;
  font-style: normal;
  font-weight: normal;
  margin: 0px;
  padding: 5px;
}
.rowheader
{
  background-color: #E7E3DB;
  border-bottom-color: #577B9C;
  border-bottom-width: 1px;
  border-left-color: #CEDAE4;
  border-left-width: 0px;
  border-right-color: #577B9C;
  border-right-width: 1px;
  border-spacing: 0px;
  border-style: solid;
  border-top-color: #CEDAE4;
  border-top-width: 0px;
  color: #363534;
  font-family: Arial, Helvetica;
  font-size: 12pt;
  font-style: normal;
  font-weight: bold;
  margin: 0px;
  padding: 3px;
  text-align: left;
  vertical-align: middle;
}
.span1
{
  color: #0D6CB9;
}
.span2
{
  color: #418708;
  font-weight: bold;
}
.systemfooter
{
  color: #012169;
  font-family: Arial, Helvetica;
  font-size: 8pt;
  font-style: normal;
  font-weight: bold;
  padding: 0px;
}
.systemtitle
{
  color: #012169;
  font-family: Arial, Helvetica;
  font-size: 14pt;
  font-weight: bold;
  padding: 0px;
}
.table
{
  background-color: #FFFFFF;
  border: 1px solid #7F9DB9;
  border-spacing: 0px;
  color: #363534;
  font-family: Arial, Helvetica;
  font-size: 12pt;
  font-style: normal;
  font-weight: bold;
  margin: 0px;
  vertical-align: middle;
}
.tl_red
{
  background-color: #DA3E26;
  border-bottom-color: #577B9C;
  border-bottom-width: 1px;
  border-left-color: #CEDAE4;
  border-left-width: 0px;
  border-right-color: #577B9C;
  border-right-width: 1px;
  border-spacing: 0px;
  border-style: solid;
  border-top-color: #CEDAE4;
  border-top-width: 0px;
  color: #FFFFFF;
  font-family: Arial, Helvetica;
  font-size: 12pt;
  font-style: normal;
  font-weight: bold;
  margin: 0px;
  padding: 3px;
  vertical-align: top;
}
.txs_no_obs
{
  color: #012169;
  font-family: Arial, Helvetica;
  font-size: 14pt;
  font-weight: bold;
  padding: 0px;
}
